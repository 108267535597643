<template>   
  <div class="h-100">
    <div v-bind:class="{'mb-2' : details.subtopic || details.target_groups.length > 0, 'mb-3' : !details.subtopic && details.target_groups.length === 0}">
      <span>{{$t('measure.Measured_from')}} {{details.start_date}} {{$t('till')}} {{details.end_date}}</span>
    </div>
    <div v-if="details.subtopic || details.target_groups.length > 0" class="item_tags mb-3">
      <CBadge v-if="details.subtopic" class="mb-1 detail">{{details.subtopic}}</CBadge>
      <CBadge v-for="group in details.target_groups" v-bind:key="group.group_id" class="mb-1 info">{{group.title}}</CBadge>
    </div>
    <div class="answers_given d-flex">
      <div v-for="answer in answersGiven" v-bind:key="answer.emoji" class="answer text-center">        
        <span v-html="getTwemoji(answer.emoji)"></span>
        <span class="mt-2 d-block percentage">{{answer.percentage.toFixed(0)}}%</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BurningMatterSummary',
  props: ['details', 'answersGiven'],
  methods: {
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }    
  }
}
</script>
