<template>
  <v-popover offset="10" :placement="popoverPlacement" trigger="hover" popoverClass="dashboard_popover profile" popoverWrapperClass="dashboard_popover_wrapper" popoverInnerClass="dashboard_popover_content" boundariesElement=".popover_trigger" @show="getUserProfile()">                  
    <div class="popover_trigger">
      <div v-if="apiBaseUrl && clientToken && userProfileImage" 
           class="profile_image d-inline-block align-middle"
           @click="openSidebarRight('user_profile', { user_id_external:  userIdExternal })"
           v-bind:style="[mode == 'user' ? { backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + userIdExternal + '/' + userProfileImage + '/' + clientToken + ')' } : 
                                           { backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/connect-deliver-sender/' + senderIdExternal + '/' + userProfileImage  + '/' + clientToken + ')' }]">
      </div>
      <div v-else-if="userName" class="profile_icon d-inline-flex align-middle" @click="openSidebarRight('user_profile', { user_id_external:  userIdExternal })">
        <userProfileAvatar :username="userName" :size="25"/>
      </div>
    </div>

    <template slot="popover">
      <CCard  class="mb-0 profile">
        <CCardBody>
          <div v-if="loadingData === true">
            <CRow>
              <CCol cols="12" lg="12">
                <loadingSpinner mode="auto" :content="$t('profile.loading_profile')"/>
              </CCol>
            </CRow>
          </div>
          <div v-if="loadingData === false">
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <center>
                  <div v-if="profile.profile_image" 
                       class="profile_image mb-2" 
                       v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + profile.user_id_external + '/' + profile.profile_image + '/' + clientToken + ')' }">
                  </div>
                  <div v-else-if="userName" class="profile_icon mb-2">
                    <userProfileAvatar :username="userName" :size="50"/>
                  </div>              
                </center>
              </CCol>              
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <center>
                  <p class="font-weight-bold mb-1" style="font-size: 1.2em;">{{profile.name}}</p>
                  <p class="mb-1"><i class="fas fa-building mr-1"/>{{profile.department_name}}</p>
                  <p v-if="profile.function !== null && profile.function !== ''" class="mb-1"><i class="fas fa-briefcase mr-1"/>{{profile.function}}</p>
                  <p v-if="showPhoneNumber && profile.phone !== null && profile.phone !== ''" class="mb-1"><i class="fas fa-phone mr-1"/>{{profile.phone}}</p>
                  <p v-if="showEmailAddress && profile.email !== null && profile.email !== ''"><i class="fas fa-envelope mr-1"/>{{profile.email}}</p>          
                  <hr style="border: #eeeeee 1px solid; width: 80%; margin: 20px 0;"/>
                  <span>{{ profile.bio !== null && profile.bio !== '' ? profile.bio : $t('profile.empty_bio_user') }}</span>
                  <hr style="border: #eeeeee 1px solid; width: 80%; margin: 20px 0;"/>
                </center>
              </CCol>            
            </CRow>
            <CRow v-if="(profile.department_name && profile.team_name) || profile.dob || profile.years_of_service || profile.months_of_service">
              <CCol v-if="profile.department_name && profile.team_name" class="pt-0 pb-0 text-center">              
                <i class="fas fa-users fa-2x mb-1"/><br/>            
                <a v-if="profile.team_id" @click="openSidebarRight('team_profile', { team_id: profile.team_id });" class="d-block">
                  <span>{{profile.department_name}} ({{profile.team_name}})</span>
                  <span v-if="profile.work_location" class="work_location"><i class="fas fa-map-marker-alt mr-1"/>{{profile.work_location}}</span>
                </a>
                <span v-else>-</span>
              </CCol>
              <CCol v-if="profile.dob" class="pt-0 pb-0 text-center">
                <i class="fas fa-birthday-cake fa-2x mb-1"/><br/>
                <span>{{profile.dob | moment("DD-MM")}}</span>
              </CCol>
              <CCol v-if="profile.years_of_service || profile.months_of_service" class="pt-0 pb-0 pb-0 text-center">
                <i class="fas fa-user-plus fa-2x mb-1"/><br/>
                <span v-if="profile.years_of_service > 0">{{profile.years_of_service}} {{ $t('common.years_of_service') }}</span>
                <span v-else>{{profile.months_of_service}} {{ $t('common.months_of_service') }}</span>
              </CCol>                               
            </CRow>           
          </div>          
        </CCardBody>
      </CCard>
    </template>
  </v-popover>
</template>

<script>
import axios from 'axios';
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'UserProfilePopover',
  components: {
    loadingSpinner,
    userProfileAvatar
  },
  data() {
    return {    
      apiBaseUrl: null,
      clientToken: null,
      profile: {
        work_location: null
      },
      loadingData: false,
      profileLoaded: false,
      showPhoneNumber: false,
      showEmailAddress: false
    }
  },
  props: ['mode', 'userName', 'userProfileImage', 'userIdExternal', 'senderIdExternal', 'popoverPlacement'], 
  methods: {
    getUserProfile() {
      // Check the profile Loaded value
      if(this.profileLoaded === false) {
        // Show the loader
        this.loadingData = true;
        // Get the profile data
        axios.get('v1/core/profile/user/' + this.userIdExternal)
        .then(res => {
          this.profile = res.data.data;
          
          setTimeout(function() {
            // Hide the loader
            this.loadingData = false;
            // Update the profile Loaded value
            this.profileLoaded = true;
          }.bind(this), 250);        
        })
        .catch(err => {
          console.error(err); 
        });
      }
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }
}
</script>