<template>
  <CRow class="reachability">
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <b-table class="data_table"
                ref="departmentTable" 
                :data="departmentData" 
                :striped="true" 
                :hoverable="true" 
                :mobile-cards="true" 
                :paginated="departmentsPaginated" 
                :per-page="departmentsPerPage" 
                :current-page.sync="currentPage" 
                :pagination-simple="isPaginationSimple" 
                :pagination-position="paginationPosition" 
                detailed 
                detail-key="name" 
                :show-detail-icon="true"
                :default-sort-direction="'desc'"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                default-sort="sentiment_score">

        <template slot-scope="department">
          <b-table-column field="name" sortable :label="$t('common.Department')" :searchable="searchEnabled" width="20%">
            <span>{{department.row.name}}</span>
          </b-table-column>
          <b-table-column field="reach.percentage" sortable :label="$t('insights.Reach')" width="25%">
            <CProgress :max="100" show-value>
              <CProgressBar :value="department.row.reach.percentage"></CProgressBar>
              <span>{{parseFloat(department.row.reach.percentage).toFixed(0)}}%</span>
            </CProgress>
          </b-table-column>              
          <b-table-column field="count" sortable :label="$t('insights.Count')" width="7.5%">
            <div class="d-flex align-items-center count">
              <span>{{department.row.count}}</span>
              <i class="icon top far fa-user ml-1"/>
            </div>
          </b-table-column>
          <b-table-column field="reach.total" sortable :label="$t('insights.Count_seen')" width="7.5%">
            <div class="d-flex align-items-center count">
              <span>{{department.row.reach.total}}</span>
              <i class="icon top far fa-user ml-1"/>
            </div>
          </b-table-column>
          <b-table-column field="sentiment_score" class="score" sortable v-bind:label="$t('common.Sentiment')" width="10%">
            <div v-if="department.row.sentiment_score" class="item_score">
              <span class="integral">{{ getScoreIntegral(department.row.sentiment_score) }}</span>
              <span class="decimal">.</span>
              <span class="fractional">{{ getScoreFracetional(department.row.sentiment_score) }}</span>
            </div>
            <span v-else>-</span>                                    
          </b-table-column>          
					<b-table-column field="total" v-for="answer in department.row.answers_given" v-bind:key="answer.emoji" :label="answer.emoji" centered>
            <template v-slot:header="{}">
              <span v-html="getTwemoji(answer.emoji)" class="th_image"></span>
            </template>
            <span v-if="answer.total > 0">{{answer.total}}</span>
            <span v-else>-</span>
          </b-table-column>
          <b-table-column field="last_emojis" v-bind:label="$t('sq.Popular_reply')" centered width="10%">
            <span v-if="department.row.most_given_answer"
                  v-html="getTwemoji(department.row.most_given_answer.emoji)"
                  v-c-tooltip="{placement: 'right', boundaries: 'scrollParent', content: department.row.most_given_answer.times_given + ' ' + $t('sq.times_given')}">
            </span>
            <span v-else>-</span>
          </b-table-column>
        </template>
        <template slot="empty">
          <div class="p-2 text-center">
            <span>{{$t('insights.No_departments_reached')}}</span>
          </div>
        </template> 
        <template slot="detail" slot-scope="department">
          <b-table class="data_table" 
                    :data="Object.values(department.row.teams)" 
                    :striped="true" 
                    :hoverable="true" 
                    :mobile-cards="true" 
                    :paginated="teamsPaginated" 
                    :per-page="teamsPerPage" 
                    :current-page.sync="currentPage" 
                    :pagination-simple="isPaginationSimple" 
                    :pagination-position="paginationPosition"
                    :default-sort-direction="'desc'"
                    :sort-icon="sortIcon"
                    :sort-icon-size="sortIconSize"
                    default-sort="sentiment_score">
            
            <template slot-scope="team">
              <b-table-column field="name" sortable :label="$t('common.Team')" :searchable="searchEnabled" width="20%">
                <span>{{team.row.name}}</span>
              </b-table-column>
              <b-table-column field="reach.percentage" sortable :label="$t('insights.Reach')" width="25%">
                <CProgress :max="100" show-value>
                  <CProgressBar :value="team.row.reach.percentage"></CProgressBar>
                  <span>{{parseFloat(team.row.reach.percentage).toFixed(0)}}%</span>
                </CProgress>
              </b-table-column>                                                                 
              <b-table-column field="count" sortable :label="$t('insights.Count')" width="7.5%">
                <div class="d-flex align-items-center count">
                  <span>{{team.row.count}}</span>
                  <i class="icon top far fa-user ml-1"/>
                </div>
              </b-table-column>
              <b-table-column field="reach.total" sortable :label="$t('insights.Count_seen')" width="7.5%">
                <div class="d-flex align-items-center count">
                  <span>{{team.row.reach.total}}</span>
                  <i class="icon top far fa-user ml-1"/>
                </div>
              </b-table-column>
              <b-table-column field="sentiment_score" class="score" sortable v-bind:label="$t('common.Sentiment')" width="10%">
                <div v-if="team.row.sentiment_score" class="item_score">
                  <span class="integral">{{ getScoreIntegral(team.row.sentiment_score) }}</span>
                  <span class="decimal">.</span>
                  <span class="fractional">{{ getScoreFracetional(team.row.sentiment_score) }}</span>
                </div>
                <span v-else>-</span>
              </b-table-column>          
              <b-table-column field="total" v-for="answer in team.row.answers_given" v-bind:key="answer.emoji" :label="answer.emoji" centered>
                <template v-slot:header="{}">
                  <span v-html="getTwemoji(answer.emoji)" class="th_image"></span>
                </template>                
                <span v-if="answer.total > 0">{{answer.total}}</span>
                <span v-else>-</span>
              </b-table-column>
              <b-table-column field="last_emojis" v-bind:label="$t('sq.Popular_reply')" centered width="10%">
                <span v-if="team.row.most_given_answer"
                      v-html="getTwemoji(team.row.most_given_answer.emoji)"
                      v-c-tooltip="{placement: 'right', boundaries: 'scrollParent', content: team.row.most_given_answer.times_given + ' ' + $t('sq.times_given')}">
                </span>
                <span v-else>-</span>
              </b-table-column>              
            </template>
            <template slot="empty">
              <div class="p-2 text-center">
                <span>{{$t('insights.No_teams_reached')}}</span>
              </div>                       
            </template>
          </b-table>
        </template>
      </b-table>
    </CCol>
  </CRow>
</template>

<script>
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'ReachabilityBurningMatter',
  props: ['departments'],
  components: {
    userProfilePopover
  },
  data() {
    return {
      departmentData: [],
      departmentsPaginated: false,
      teamsPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      departmentsPerPage: 15,
      teamsPerPage: 10,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',      
      searchEnabled: false
    }
  },
  methods: {
    getScoreIntegral(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(0, score.indexOf('.'));
      } else {
        score = score.toFixed(1).toString();
        return score.substring(0, score.indexOf('.'));
      }
    },
    getScoreFracetional(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(score.indexOf('.') + 1);
      } else {
        score = score.toFixed(1).toString();
        return score.substring(score.indexOf('.') + 1);
      }
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    getAnswerHighlight(array, amount) {
      // Clone the array to prevent infinite loops
      var highlightData = array.slice(0);
      // Filter the highlights to make sure scores are available
      highlightData = highlightData.filter(i => ![0].includes( i.total ));      
      // Sort the highlights on the total amount
      highlightData.sort(function(x, y) {
        if(x['total'] == y['total']) return 0;
        else if(parseInt(x['total']) < parseInt(y['total'])) return 1;
        else return -1;
      });
      // Return the highlights
      return highlightData.slice(0, amount);
    },       
  },
  mounted:function() {
    // Set the prop as the table data
    this.departmentData = Object.values(this.departments);
    
    for(var d = 0; d < this.departmentData.length; d++) {
      let highlight = this.getAnswerHighlight(this.departmentData[d].answers_given, 1);
      (highlight.length > 0) ? this.departmentData[d].most_given_answer = { times_given: highlight[0].total, emoji: highlight[0].emoji } : this.departmentData[d].most_given_answer = null;
      
      this.departmentData[d].teams = Object.values(this.departmentData[d].teams);
       
      for(var t = 0; t < this.departmentData[d].teams.length; t++) {
        let highlight = this.getAnswerHighlight(this.departmentData[d].teams[t].answers_given, 1);
        (highlight.length > 0) ? this.departmentData[d].teams[t].most_given_answer = { times_given: highlight[0].total, emoji: highlight[0].emoji } : this.departmentData[d].teams[t].most_given_answer = null;
      }
    }
  }
}
</script>
