<template>
  <div>
    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <h1 class="dashboard_page_title flex-grow-1">{{$t('insights.Burning_matter_insights')}}</h1>
          <div v-if="showDownloadButtons" class="insights_download_buttons text-right">
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="link m-0" color="primary" @click="makeScreenshot()">
                <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
              </CButton>                
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

    <CRow class="insights_filters">
      <CCol>
        <multiselect class="data_table"
                      v-model="selectedDepartments" 
                      :options="departments" 
                      :multiple="true"                
                      :placeholder="$t('common.Select_department')"
                      :selectLabel="$t('common.Add_department')" 
                      :selectedLabel="$t('Added')"
                      :deselectLabel="$t('common.Remove_department')"
                      track-by="department_id" 
                      label="department_name"
                      @search-change="asyncFindDepartment"
                      @input="getBurningMatterData()">
          <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
        </multiselect>
      </CCol>
      <CCol>
        <multiselect class="data_table"
                      v-model="selectedTeams" 
                      :options="teams" 
                      :multiple="true"                
                      :placeholder="$t('common.Select_team')" 
                      :selectLabel="$t('common.Add_team')" 
                      :selectedLabel="$t('Added')"
                      :deselectLabel="$t('common.Remove_team')"
                      track-by="team_id" 
                      label="team_name"
                      @search-change="asyncFindTeam"
                      @input="getBurningMatterData()">
          <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
        </multiselect>
      </CCol>
      <CCol>
        <multiselect class="data_table"
                      v-model="selectedWorkLocations" 
                      :options="workLocations" 
                      :multiple="true"                
                      :placeholder="$t('common.Select_work_location')" 
                      :selectLabel="$t('common.Add_work_location')" 
                      :selectedLabel="$t('Added')"
                      :deselectLabel="$t('common.Remove_work_location')"
                      track-by="tag" 
                      label="label"
                      @search-change="asyncFindWorkLocation"
                      @input="getBurningMatterData()">
          <span slot="noResult">{{ $t('common.No_work_locations_found') }}</span>
          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
        </multiselect>
      </CCol>      
    </CRow>    
  
    <CRow ref="screenshotContent" class="insights" v-bind:class="{'printing' : printingInsights}">
      <CCol cols="5" xl="5" lg="5" md="5" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">      
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{burningMatterInsightsData.details.question}}
              </CCol>
            </CRow>      
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <BurningMatterSummary v-else :details="burningMatterInsightsData.details" :answersGiven="burningMatterInsightsData.answers_given"/>
          </CCardBody>
        </CCard>
      </CCol>
            
      <CCol cols="4" xl="4" lg="4" md="4" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('insights.reach')}}
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <div v-else class="gauge_with_digits">
              <Gauge :gaugeChartSeries="gaugeChartSeries" :companyPrimaryColor="companyPrimaryColor"/>
              <ReachDigits :nDigit="burningMatterInsightsData.reach.total" :qDigit="burningMatterInsightsData.audience.total"/>
            </div>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol cols="3" xl="3" lg="3" md="3" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                {{$t('common.Sentiment')}}
              </CCol>       
            </CRow>
          </CCardHeader>
          <CCardBody class="text-center">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Grade v-else :grade="burningMatterInsightsData.sentiment_score" :centered="true"/>
          </CCardBody>
        </CCard>
      </CCol>      

      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
                {{$t('insights.Reachability_organisation')}}
              </CCol> 
            </CRow>
          </CCardHeader>
          <CCardBody v-bind:class="{'p-0' : !loadingData}">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <ReachabilityBurningMatter v-else :departments="burningMatterInsightsData.departments"/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>   
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Multiselect from 'vue-multiselect';

import Grade from '@/components/insights/common/Grade.vue';
import Gauge from '@/components/insights/common/Gauge.vue';
import ReachDigits from '@/components/insights/common/ReachDigits.vue';
import ReachabilityBurningMatter from '@/components/insights/measure/ReachabilityBurningMatter.vue';
import BurningMatterSummary from '@/components/insights/measure/BurningMatterSummary.vue';

export default {
  name: 'Category',
  components: {
    loadingSpinner,
    Multiselect,
    Grade,
    Gauge,
    ReachDigits,
    BurningMatterSummary,
    ReachabilityBurningMatter
  },
  watch: { 
    '$route.params.id': {
      handler() {
        // Get the burningMatterIdExternal from the route params
        this.burningMatterIdExternal = this.$route.params.id;
        // Get the data
        this.getBurningMatterData();        
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      loadingData: false,
      burningMatterIdExternal: null,
      burningMatterInsightsData: {
        details: { question: null },
        sentiment_score: '0.0'
      },
      showDownloadButtons: false,
      printingInsights: false,
      companyPrimaryColor: null,
      gaugeChartSeries: [],
      selectedTeams: [],
      teams: [],
      selectedDepartments: [],
      departments: [],
      selectedWorkLocations: [],
      workLocations: []      
    }
  },
  methods: {
    getBurningMatterData() {
      let params = {};
      params.departments = [];
      params.teams = [];
      params.work_locations = [];

      for(let d = 0; d < this.selectedDepartments.length; d++) {
        params.departments.push(this.selectedDepartments[d].department_id);
      }

      for(let t = 0; t < this.selectedTeams.length; t++) {
        params.teams.push(this.selectedTeams[t].team_id);
      }

      for(let l = 0; l < this.selectedWorkLocations.length; l++) {
        params.work_locations.push(this.selectedWorkLocations[l].tag);
      }      
      // Start the loader
      this.loadingData = true;
      // Clear the gaugeChartSeries array
      this.gaugeChartSeries = [];         
      // Get the topics insights data
      axios.post(process.env.VUE_APP_API_URL + '/v1/measure/burning_matter/insights/' + this.burningMatterIdExternal, params)
      .then(res => {
        // Set the burningMatterInsightsData
        this.burningMatterInsightsData = res.data.data;
        // Add the reach percentage to the gaugeChartSeries array
        this.gaugeChartSeries.push(this.burningMatterInsightsData.reach.percentage.toFixed(0));
        // Set the companyPrimaryColor value
        this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingData = false;              
          // Update the showDownloadButtons value
          this.showDownloadButtons = true;
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });                  
    },
    makeScreenshot() {
      window.scroll(0,0);
      // Update printingInsights value
      this.printingInsights = true;
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
        // Reset the printingInsights value
        this.printingInsights = false;
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");

      let exportSubject = this.burningMatterInsightsData.details.question;
      exportSubject = exportSubject.replace(/\s/g, '-');
      exportSubject = exportSubject.replace(/[^a-zA-Z ]/g, '');
      exportSubject = exportSubject.toLowerCase();      

      link.setAttribute("download", "burning_matter_insights_" + exportSubject + "_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    },
    asyncFindDepartment (query) {
      let searchTerm = query;
      this.departments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.departments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam (query) {
      let searchTerm = query;
      this.teams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.teams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindWorkLocation(query) {
      let searchTerm = query;
      this.workLocations = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/workLocation/' + searchTerm)
        .then(res => {      
          this.workLocations = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    }    
  }
}
</script>