<template>
  <apexchart v-if="gaugeChartOptions.fill.colors.length > 0"
              class="gauge"
              type="radialBar"
              :options="gaugeChartOptions"
              :series="gaugeChartSeries">
  </apexchart>
</template>

<script>

export default {
  name: 'Gauge',
  props: ['gaugeChartSeries', 'companyPrimaryColor'],
  data() {
    return {
      gaugeChartOptions: {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              show: true,
              background: '#e7e7e7',              
              strokeWidth: '97%',
              opacity: 1,
              margin: 0, 
              dropShadow: {
                enabled: true,
                color: '#999',
                top: 2,
                left: 0,
                blur: 2,
                opacity: 1
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                show: true,
                fontSize: '25px',
                fontFamily: 'QuickSand, sans-serif',
                fontWeight: 700,
                offsetY: -10,
              }
            }
          }
        },
        fill: {
          colors: []
        },
      }      
    }
  },
  mounted:function() {
    // Set the color of the chart
    this.gaugeChartOptions.fill.colors.push(this.companyPrimaryColor);
  }
}
</script>
