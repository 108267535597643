<template>
  <div class="reach_digits text-center">
    <span v-c-tooltip="{placement: 'bottom', boundaries: 'scrollParent', content: $t('measure.digits_explainer')}">N = {{nDigit}} / Q = {{qDigit}}</span>
  </div>
</template>

<script>

export default {
  name: 'ReachDigits',
  props: ['nDigit', 'qDigit']
}
</script>
