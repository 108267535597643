<template>   
  <div class="grade_score" v-bind:class="{'h-100 d-flex align-items-center justify-content-center' : centered}">
    <span class="integral">{{ scoreIntegral }}</span>
    <span class="decimal">.</span>
    <span class="fractional">{{ scoreFractional }}</span>
  </div>
</template>

<script>

export default {
  name: 'Grade',
  props: ['grade', 'centered'],
  watch: {
    $props: {
      handler() {
        if(this.grade) {
          let grade = null;
          // Set the grade value
          (typeof(this.grade) === 'number') ? grade = this.grade.toFixed(1) : grade = this.grade;
          // Set the score integral
          this.scoreIntegral = this.getScoreIntegral(grade);
          // Set the score fractional
          this.scoreFractional = this.getScoreFractional(grade);
        }
      },
      deep: true,
      immediate: true,
    }
  },   
  data() {
    return {
      scoreIntegral: 0,
      scoreFractional: 0
    }
  },
  methods: {
    getScoreIntegral(score) {
      if(score) return score.substring(0, score.indexOf('.'));
    },
    getScoreFractional(score) {
      if(score) return score.substring(score.indexOf('.') + 1);
    }
  }
}
</script>
